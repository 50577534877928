import React from "react";
import styled from "styled-components";
import Link from "../Link/Link";
import Heading from "../Heading/Heading";

export enum GardenEntryState {
  SEEDLING = "seedling",
  BUDDING = "budding",
  EVERGREEN = "evergreen",
}

interface Props {
  title: string;
  href: string;
  lastModified: Date,
  entryState: GardenEntryState,
}

export default ( { title, href, lastModified, entryState }: Props ) => {
  let entryStateElem;
  switch (entryState) {
    case GardenEntryState.EVERGREEN:
      entryStateElem = "Evergreen 🌲";
      break;
    case GardenEntryState.BUDDING:
      entryStateElem = "Budding 🌿";
      break;
    case GardenEntryState.SEEDLING:
    default:
      entryStateElem = "Seedling 🌱";
  }

  return (
    <Wrapper href={href}>
      <CardTitle type={"large-title"}>
        {title}
      </CardTitle>
      <MetadataWrapper>
        <LastModified>
          {new Intl.DateTimeFormat( "en-US", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          } ).format( lastModified )}
        </LastModified>
        <EntryState>{entryStateElem}</EntryState>
      </MetadataWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Link)`
  background: var(--color-gray-300);
  border-bottom: 8px solid var(--color-gray-500);

  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  text-decoration: none;
  color: var(--color-text);

  padding: 1rem;
  //border-radius: 5px;
  //border: 1px solid hsl(152deg, 41%, 52%);

  transition: transform 0.1s ease-in-out;

  &:active {
    outline: 2px auto var(--color-primary);
    outline-offset: 2px;
  }

  &:hover {
    transform: scale(1.05);
  }

`;

const CardTitle = styled( Heading )`
  display: flex;
  flex: 1;
  align-items: center;
  //text-align: left;
  font-size: 1.5rem;
  line-height: 1.25;
  letter-spacing: .05rem;
`;

const MetadataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--color-gray-600);
  padding-top: 1rem;
  font-size: 0.8rem;
  letter-spacing: .05rem;
  color: var(--color-secondary)
`;

const LastModified = styled.div``;

const EntryState = styled.div``;
