import React from "react"
import styled from "styled-components";
import Header from '../Header/Header';
import MaxWidthWrapper from "../MaxWidthWrapper/MaxWidthWrapper";
import Heading from "../Heading/Heading";
import Card, { GardenEntryState } from "../Card/Card";

const Hero = () => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <Header type={'hero'}/>
      </HeaderWrapper>

      <ContentWrapper>
        <CardWrapper>
          <HeroCard href={"/"} title={"Digital Gardening"} entryState={GardenEntryState.SEEDLING}
                    lastModified={new Date()}/>
          <HeroCard href={"/"} title={"Foo Bar"} entryState={GardenEntryState.EVERGREEN} lastModified={new Date()}/>
          <HeroCard href={"/"} title={"All these Things with long titles!"} entryState={GardenEntryState.BUDDING}
                    lastModified={new Date()}/>
          <HeroCard href={"/"} title={"Digital Gardening"} entryState={GardenEntryState.SEEDLING}
                    lastModified={new Date()}/>
          <HeroCard href={"/"} title={"Foo Bar"} entryState={GardenEntryState.EVERGREEN} lastModified={new Date()}/>
          <HeroCard href={"/"} title={"All these Things with long titles!"} entryState={GardenEntryState.BUDDING}
                    lastModified={new Date()}/>
        </CardWrapper>
        <HeroHeading type={"large-title"}>
          Hello there! Welcome to my corner of the internet.
        </HeroHeading>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  z-index: 3;
  min-height: 100vh;
  background: var(--color-primary);
  display: flex;
  flex-direction: column;

  border-bottom: 8px solid hsl(150deg, 42%, 62%);
`;

const HeaderWrapper = styled( MaxWidthWrapper )`
  position: sticky;
  top: 0;
  z-index: 2;
  background: var(--color-primary);
`;

const ContentWrapper = styled( MaxWidthWrapper )`
  z-index: 1;

  flex: 1;
  align-items: center;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 500px) {
    flex-direction: column-reverse;
  }
`;

const HeroHeading = styled( Heading )`
  color: var(--color-gray-200);
  background: var(--color-background-secondary);
  max-width: 15ch;
  letter-spacing: .6px;
  line-height: 1.2;
  font-weight: var(--font-weight-bold);
  padding: 2rem;
  margin: 0 4rem;

  border-bottom: 8px solid hsl(155deg, 43%, 18%);

  @media (max-width: 500px) {
    width: 100%;
    max-width: none;
    margin-bottom: 2rem;
  }
`;

const HeroCard = styled( Card )``;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1rem;
  flex: 1;
  margin: 0 2rem;

  @media (max-width: 500px) {
    width: 100%;
  }

  @media (max-width: 966px) {
    > :nth-last-child(-n+2) {
      display: none;
    }
  }
`;

export default Hero;
