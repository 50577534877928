import * as React from "react"
import SEO from "../components/SEO/SEO"
import styled from "styled-components";
import Hero from "../components/Homepage/Hero";
import MaxWidthWrapper from "../components/MaxWidthWrapper/MaxWidthWrapper";
import Footer from "../components/Footer/Footer";
import Heading from "../components/Heading/Heading";
import Paragraph from "../components/Paragraph/Paragraph";

const Homepage = () => (
  <Wrapper>
    <SEO title={"Home"}/>
    <Hero/>

    <Main as={"main"}>
      <NowSection>
        <Heading type={"section-title"}>now</Heading>

        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium blanditiis deserunt dolorem ducimus
          exercitationem fuga maxime neque nobis officia, perspiciatis placeat porro provident, quisquam, similique sint
          ullam unde vel voluptates?
        </Paragraph>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium blanditiis deserunt dolorem ducimus
          exercitationem fuga maxime neque nobis officia, perspiciatis placeat porro provident, quisquam, similique sint
          ullam unde vel voluptates?
        </Paragraph>
      </NowSection>

      <AboutSection>
        <Heading type={"section-title"}>about</Heading>

        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium blanditiis deserunt dolorem ducimus
          exercitationem fuga maxime neque nobis officia, perspiciatis placeat porro provident, quisquam, similique sint
          ullam unde vel voluptates?
        </Paragraph>
      </AboutSection>
    </Main>
    <Footer/>
  </Wrapper>
);

const Wrapper = styled.div`
  background: var(--color-background);
  transition: background 200ms;

  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
`;

const Main = styled( MaxWidthWrapper )`
  flex-grow: 1;
  flex-shrink: 0;

  position: relative;
  z-index: 2;

  padding-top: 64px;
`;

const NowSection = styled.section`
`;

const AboutSection = styled.section`
`;

export default Homepage;
